<template>
  <v-main>
    <v-app id="app">
      <v-layout id="nav" class="align-end justify-end mt-2 ml-8 mr-8">
        <router-link class="ml-2" to="/"><span class="ml-4">Home</span></router-link>
        <router-link class="ml-2" to="/about"><span class="ml-4">About</span></router-link>
      </v-layout>
      <router-view></router-view>
      <v-footer absolute style="background-color: rgb(235,222,201)">
        <v-layout class="d-flex flex-row align-center justify-center" style="background-color: rgb(235,222,201)">

          <v-btn plain icon v-on:click="clickTwitter">
            <v-icon>mdi-twitter</v-icon>
            <a id="social" class="pr-6" style="color: rgb(46,40,39); absolute: true;" href="https://twitter.com/simcoulter">Follow me</a>
          </v-btn>
        </v-layout>
      </v-footer>
    </v-app>
  </v-main>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data: () => ({
    message: 'Stoic AI',
    finished: false,
    quotes: []
  }),
  mounted () {
    this.$root.$on('restart', () => {
      this.finished = false
    })
  },
  created () {
    this.$store.dispatch('fetchQuotes')
  },
  methods: {
    clickTwitter () {
      window.location.href = 'https://twitter.com/simcoulter'
    }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: rgb(235,222,201);
}

#nav {
  max-height: 24px;
}

a {
  color:rgb(155,148,135) !important;
  font-weight: bold !important;
  text-decoration: none;
}

.router-link-exact-active {
  text-decoration: underline;
  color: #2c3e50 !important;
}

#social:hover {
  text-decoration: underline;;
}

</style>
