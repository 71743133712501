<template>
  <div>
    <div data-app>
      <ModalMessage/>
      <div id="app">
        <h1> {{ message }} </h1>
        <p class="pb-6">Decide if a quote is generated from a real Stoic philosopher or using <a href="https://openai.com/blog/gpt-3-apps/">GPT3</a></p>
        <Results v-bind:showResults="finished" v-bind:quotes="quotes" v-if="finished"/>
        <Quote v-if="finished == false"/>
      </div>
    </div>
  </div>
</template>

<script>
import Quote from '../components/Quote.vue'
import ModalMessage from '../components/ModalMessage.vue'
import Results from '../components/Results.vue'

export default {
  name: 'Home',
  components: {
    Quote,
    ModalMessage,
    Results
  },
  data: function () {
    return {
      message: 'Stoic AI',
      finished: false,
      quotes: []
    }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: rgb(235,222,201);
  display: flex;
  flex-direction: column;
}

h1 {
  color: rgb(46,40,39),
}

a { text-decoration: none;
    color: rgb(46,40,39) }

#app {
  /* background: url(assets/background3.jpg) no-repeat center center fixed;  */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-bottom: 50px;
}

</style>
