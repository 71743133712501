import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    primary: '#673ab7',
    secondary: '#9c27b0',
    accent: '#e91e63',
    error: '#f44336',
    warning: '#00bcd4',
    info: '#009688',
    success: '#4caf50'
  }
})
