import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueGtag, {
  config: { id: 'G-SY3J6F8F6Y' },
  pageTrackerTemplate: function (to, from) {
    return {
      page_title: to.name,
      page_path: router.options.base + to.path,
      page_location: window.location.href
    }
  }
}, router)
