import Vue from 'vue'
import Vuex from 'vuex'
import quoteData from '@/assets/quotes.json'

Vue.use(Vuex)
const shouldLog = false
const print = (text) => {
  if (shouldLog) {
    console.log(text)
  }
}
export default new Vuex.Store({
  state: {
    quotes: [],
    selectedQuotes: []
  },
  strict: process.env.NODE_ENV !== 'production',
  mutations: {
    setQuotes (state, payload) {
      state.quotes = payload
    },
    setSelectedQuotes (state, payload) {
      state.selectedQuotes = payload
      print(`setSelectedQuotes - ${payload} - ${state.selectedQuotes}`)
    },
    insertSelectedQuote (state, payload) {
      print(`insertSelectedQuote before: ${state.selectedQuotes} - ${payload}`)
      state.selectedQuotes.push(payload)
      print(`insertSelectedQuote after: ${state.selectedQuotes} - ${payload}`)
    }
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    fetchQuotes ({}) {
      print('fetchQuotes')
      if (this.state.quotes.length === 0) {
        this.commit('setQuotes', quoteData.quotes)
        this.commit('setSelectedQuotes', [])
      }
    },
    // eslint-disable-next-line no-empty-pattern
    addSelectedQuote ({}, quote) {
      print('AddSelectedQuote')
      this.commit('insertSelectedQuote', quote)
    }
  }
})
