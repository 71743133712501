<template>

    <div id="dialog"                                                                  >
      <v-dialog
      v-model="modalVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="headline lighten-2">

        </v-card-title>

        <v-card-text class="subtitle-1">
          {{ text }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeModalAndEmit"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
export default {
  name: 'ModalMessage',
  data: function () {
    return {
      title: '',
      text: '',
      modalVisible: false,
      identifier: ''
    }
  },
  created () {
    this.$root.$on('closeModal', () => {
      this.modalVisible = false
    })
    this.$root.$on('showModal', (identifier, title, text) => {
      this.showModal(identifier, title, text)
    })
  },
  methods: {
    showModal (identifier, title, text) {
      this.identifier = identifier
      this.title = title
      this.text = text
      this.modalVisible = true
    },
    closeModalAndEmit () {
      // console.log('ModalMessage: closeModalAndEmit');
      this.$root.$emit('closeModal', this.identifier)
    }
  }
}
</script>

<style scoped>
#quote-bubble {
  margin: 20px 10% 20px 10%;
  background-color: rgb(241, 205, 106);
  border-radius: 20px;
}

#button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

v-btn {
  padding: 500px;
  width: 200px;
}
</style>
