<template>
  <div id="results" v-if="showResults">
    <p>Stuff and more stuff</p>
    <div id="border">
      <h1>Quote Results</h1>
      <p>Accuracy: {{ accuracy }} </p>
      <div id="progress">
        <v-progress-linear v-bind:value="accuracy"></v-progress-linear>
      </div>
      <p>Correct: {{correct}} </p>
      <p>InCorrect: {{incorrect}} </p>
      <div id="review-quotes">
        <h3>Review Quotes</h3>
        <br>

        <v-layout class="d-flex align-start justify-start" v-bind:quote='quote' v-bind:key="quote.identifier" v-for="(quote) in batch">
          <div id="quote-row" class="d-flex justify-start mb-4">
            <v-icon class="pa-2" color="white lighten-2" v-if="quote.selectedCorrect">mdi-thumb-up</v-icon>
            <v-icon class="pa-2" color="red lighten-2" v-if="quote.selectedCorrect == false">mdi-thumb-down</v-icon>
            <v-card
              color="rgb(241, 205, 106)"
              class="d-flex flex-grow-1"
            >
              <v-card-title class="d-flex">
                <!-- <span class="d-flex title font-weight-light">{{ quote.author }}</span> -->
                <span v-if="quote.isAi">This was generated by a bot</span>
                <span class="d-flex" v-if="quote.isAi == false">This was generated by {{quote.author}}</span>
              </v-card-title>

              <v-card-text class="headline font-weight-bold d-flex align-center">
                <span>{{ quote.message }} </span>
              </v-card-text>
            </v-card>
            </div>
        </v-layout>
      </div>
      <v-btn @click="tryAgain">Try again</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Results',
  props: {
    showResults: Boolean,
    quotes: Array
  },
  data: function () {
    return {}
  },
  computed: {
    batch: function () {
      return this.quotes.filter((quote) => quote.selectedCorrect != null)
    },
    accuracy: function () {
      console.log(`accuracy: correct: ${this.correct} quotes: ${this.batch.length} result: ${(this.correct / this.batch.length) * 100}`)
      const value = (this.correct / this.batch.length) * 100
      // return `${value}%`;
      return value
    },
    correct: function () {
      const count = this.batch.map((quote) => quote.selectedCorrect === true ? 1 : 0)
      return count.reduce((total, item) => total + item)
    },
    incorrect: function () {
      const count = this.batch.map((quote) => quote.selectedCorrect === false ? 1 : 0)
      return count.reduce((total, item) => total + item)
    }
  },
  methods: {
    quoteText: function (quote) {
      return quote.message
    },
    tryAgain: function () {
      console.log('Try Again')
      this.$root.$emit('restart')
    }
  }
}
</script>

<style scoped>

span {
  color: white;
}

#quote-row {
  flex-grow: 1;
  /* background-color: red; */
}

.v-icon {
  flex-grow: 0;
  flex-shrink: 0;
  /* background-color: royalblue; */
}

#border {
  border-radius: 15%;
  padding: 20px 20px 60px 20px ;
  /* max-width: 60%; */
  /* min-width: 50%; */
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  /* align-items: center; */

  background-color: lightgreen;
}

</style>
