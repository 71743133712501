<template>
  <div id="quote-background" class="d-flex flex-column align-self-center">
    <v-divider style="width: 80%;" inset></v-divider>
    <div id="quote-bubble">
      <h1>{{ activeMessage }}</h1>
    </div>
    <v-divider style="width: 80%;" inset></v-divider>
    <div class="justify-end align-end flex-wrap pa-6" id="button-container">
      <p>Can you guess who wrote this: </p>
      <v-btn class="ma-2" color="white" v-on:click="realSelected">Real philosopher</v-btn>
      <v-btn class="ma-2" color="white" v-on:click="generatedSelected">AI Bot</v-btn>
    </div>

    <span v-if="selectedBatch.length > 0"><h1>History</h1></span>
    <v-layout class=".d-flex justify-center pb-2" v-bind:quote='quote' v-bind:key="quote.identifier" v-for="(quote) in selectedBatch">
      <div id="quote-row">
        <v-layout id="quote-card" class="flex-column ma-4 align-start justify-center">
          <v-layout id="quote-title" class="flex-row align-center pl-1 pt-1">
            <span id="profile-bot" v-if="quote.isAi">🤖</span>
            <v-img v-if="quote.isAi == false" src="face.png" alt="stoic face" id="profile"/>
            <div>
              <span class="pl-2" v-if="quote.isAi">GPT3 Generated</span>
              <span class="pl-2" v-if="quote.isAi == false">{{quote.author}}</span>
            </div>
          </v-layout>

          <v-layout
                class="d-flex flex-row flex-grow-0 flex-shrink-0 justify-center pl-2"
              >
            <span class="headline">{{ quote.message }}</span>
            <div class="">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="pa-2" color="green lighten-2" v-if="quote.selectedCorrect">mdi-thumb-up</v-icon>
                </template>
                <span>You guessed correctly</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="pa-2" color="red lighten-2" v-if="quote.selectedCorrect == false">mdi-thumb-down</v-icon>
                </template>
                <span>You guessed in-correctly</span>
              </v-tooltip>
            </div>
          </v-layout>
        </v-layout>
      </div>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'Quote',
  data: function () {
    return {
      batch: [],
      batchMaxLength: 999999,
      finishedBatch: false,
      selectedIndex: 0,
      activeMessage: '',
      resultText: '',
      modalTitle: 'Result'
    }
  },
  computed: {
    quotes () {
      return this.$store.state.quotes
    },
    selectedBatch: function () {
      const selected = [...this.$store.state.selectedQuotes]
      const sorted = selected.sort((a, b) => a.selectedTime < b.selectedTime)
      return sorted
    }
  },
  mounted () {
    this.startBatch()
    this.$root.$on('closeModal', (identifier) => {
      if (identifier === 'quote-modal') {
        if (this.finishedBatch === true) {
          this.startBatch()
        } else {
          this.selectNextQuote()
        }
      }
    })
  },
  methods: {
    trackQuoteSelected (quoteType, correct) {
      this.$gtag.event(quoteType, {
        event_category: 'quotes',
        event_label: `selected-${correct}`
      })
    },
    copyAndShuffle (original) {
      var a = original.slice()
      for (var i = a.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]]
      }
      return a
    },
    startBatch () {
      const maxLen = Math.min(this.batchMaxLength, this.quotes.length)
      this.batch = this.copyAndShuffle(this.quotes).slice(0, maxLen)
      this.finishedBatch = false
      this.selectedIndex = 0

      const msg = this.batch[0].message
      this.activeMessage = msg
    },
    endBatch () {
      this.finishedBatch = true
      this.$emit('finishedBatch', this.quotes)
    },
    showModal (title, text) {
      this.$root.$emit('showModal', 'quote-modal', title, text)
    },
    selectNextQuote () {
      var num = this.selectedIndex + 1
      if (num > this.batch.length - 1) {
        this.endBatch()
      } else {
        this.selectedIndex = num
        this.activeMessage = this.batch[num].message
      }
    },
    realSelected () {
      if (this.batch[this.selectedIndex].isAi === true) {
        this.showModal(this.modalTitle, 'False, This was GPT3 generated')
        this.batch[this.selectedIndex].selectedCorrect = false
      } else {
        this.showModal(this.modalTitle, `This is a real quote from ${this.batch[this.selectedIndex].author}`)
        this.batch[this.selectedIndex].selectedCorrect = true
      }
      this.batch[this.selectedIndex].selectedTime = Date.now()
      this.trackQuoteSelected('real-selected', this.batch[this.selectedIndex].selectedCorrect)
      this.$store.dispatch('addSelectedQuote', this.batch[this.selectedIndex])
    },
    generatedSelected () {
      if (this.batch[this.selectedIndex].isAi === true) {
        this.showModal(this.modalTitle, 'Correct, This is an GPT3 generated quote')
        this.batch[this.selectedIndex].selectedCorrect = true
      } else {
        this.showModal(this.modalTitle, `False, this was a real quote from ${this.batch[this.selectedIndex].author}`)
        this.batch[this.selectedIndex].selectedCorrect = false
      }
      this.batch[this.selectedIndex].selectedTime = Date.now()
      this.trackQuoteSelected('generated-selected', this.batch[this.selectedIndex].selectedCorrect)
      this.$store.dispatch('addSelectedQuote', this.batch[this.selectedIndex])
    }
  }
}
</script>

<style scoped>

h1 {
  font-family: 'sans-serif', 'Verdana';
  background: transparent;
  font-stretch: condensed;
  text-size-adjust: auto;
}
#quote-background {
  max-width: 800px;
}

#quote-bubble {
  margin: 20px 10% 20px 10%;
  max-height: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-grow: 0;

}

#quote-bubble.child {
  width: 500%; height: 10%;
}

#button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* History */

#profile {
  max-width: 35px;
  background-color: white;
  border-radius: 50%;
}

#profile-bot {
  font-size: 1.6rem;
  background-color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

#quote-row {
  flex-grow: 1;
}

.v-icon {
  flex-grow: 0;
  flex-shrink: 0;
}

#quote-card {
  border: 2px solid #cb997e;
  border-radius: 5px;
  background: transparent;
}

</style>
